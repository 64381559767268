import {
  Grid,
  Hidden,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import theme from '../../../common/Theme';
import { SanityBlogPreview } from '../../cmsClient';
import BlogMetadata from '../BlogMetadata';
import { ImageAssetRender } from '../../../common/sanityIo/BlockContentRenderer';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  mainImage: {
    width: '100%',
  },
  metadataContainer: {
    paddingBottom: theme.spacing(1),
  },
  metaData: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  category: {
    color: '#565190',
  },
  responsiveTitle: {
    [theme.breakpoints.up('md')]: theme.typography.h4,
  },
  snippetContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0),
    },
    padding: theme.spacing(1, 0, 3, 0),
  },
  snippet: {
    fontFamily: 'DTL Documenta',
    letterSpacing: '0.1px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    color: theme.palette.secondary.main,
  },
  readMore: {
    fontFamily: 'IBM Plex Sans',
    color: 'black',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.4px',
    borderBottom: `4px solid ${theme.palette.background.default}`,
  },
  underlineHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export type BlogPostPreviewProps = {
  preview: SanityBlogPreview;
  autoHideSnippet?: boolean;
};

const BlogPostPreview: FunctionComponent<BlogPostPreviewProps> = ({
  preview,
  autoHideSnippet,
}) => {
  const classes = useStyles(theme);
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      item
      spacing={mdUp ? 2 : 1}
      className={classes.root}
      alignItems='flex-start'
    >
      <Grid container item xs={12} md={5} justify='center'>
        <Grid container item xs={12} sm={8} md={12}>
          {ImageAssetRender(
            preview?.mainImage,
            preview?.mainImageCaption,
            classes.mainImage
          )}
        </Grid>
      </Grid>
      <Grid container direction='column' item xs={12} md={6}>
        <Grid item className={classes.metadataContainer}>
          <BlogMetadata
            category={preview?.category}
            publishedAt={
              preview?.publishedAt ? preview?.publishedAt : preview?._createdAt
            }
          />
        </Grid>
        <Grid item>
          <Link
            href={'/blog/' + preview?.slug?.current}
            classes={{ underlineHover: classes.underlineHover }}
          >
            <Typography
              color='textSecondary'
              variant='h5'
              className={classes.responsiveTitle}
            >
              {preview?.title}
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.snippetContainer}>
          <Hidden smDown={autoHideSnippet}>
            {preview?.snippet ? (
              <Link
                href={'/blog/' + preview?.slug?.current}
                classes={{ underlineHover: classes.underlineHover }}
              >
                <Typography className={classes.snippet}>
                  {preview?.snippet?.substring(0, 300)} ...
                </Typography>
              </Link>
            ) : (
              <></>
            )}
          </Hidden>
        </Grid>
        <Grid container item>
          <Grid item>
            <Link
              href={'/blog/' + preview?.slug?.current}
              classes={{ underlineHover: classes.underlineHover }}
            >
              <Typography variant='body1' className={classes.readMore}>
                Read More
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogPostPreview;
