const changesMetaDescription = (metaName,descriptionContent) => {
    const meta = document.getElementsByTagName('meta');
    for (let i = 0; i < meta.length; i++) {
      if (meta[i].getAttribute('name') === metaName) {
        meta[i].setAttribute("content", descriptionContent);
        return meta[i].getAttribute('content');
      }
    }
}

export default changesMetaDescription;