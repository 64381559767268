import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import '../scss/TopButton.scss';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const TopButton = (props: {
    scrollDownLimit: number
}) => {
    const classes = useStyles();
    const [booleanDisplay, setBooleanDisplay] = useState(false);

    const checkScrollDown = () => {
        if( document.documentElement.scrollTop > props.scrollDownLimit){
            setBooleanDisplay(true);
        } else { setBooleanDisplay(false) }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkScrollDown);
    });

    return(
        <IconButton 
            onClick={()=>{document.documentElement.scrollTop = 0}}  
            aria-label="delete" 
            className={
                booleanDisplay ? classes.margin + " class-TopButton" : 
                classes.margin + " display-none class-TopButton"
            }>
            <ExpandLessIcon fontSize="large" />
        </IconButton>
    )
}

export default TopButton;