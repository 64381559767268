import React, { useState, useEffect } from 'react'
import { Link } from '@material-ui/core'
import sanityClient from '../../../sanityClient'
import { ImageAssetRender } from '../../../common/sanityIo/BlockContentRenderer'

const BlogBody = (props: { BodyData?: [{}] }) => {
  const [dataBlog, setDataBlog] = useState<any[] | undefined>()
  let counterNumber = 0
  let buttonArray = ['JOB OPPORTUNITIES', 'BROWSE BRANDS']
  useEffect(() => {
    setDataBlog(props.BodyData)
  }, [props])

  // Method for render the text written in Sanity and stack the styles applied in this text in the web page
  const renderBodyBlog: any = (itemTwo: any, _key: any, item: any) => {
    return (
      <span
        key={_key}
        className={`${itemTwo.marks.includes('centerAlign') ? 'centerAlign' : ''} ${
          itemTwo.marks.includes('strong') ? 'strong' : ''
        } ${itemTwo.marks.includes('dropCap') ? 'dropCap' : ''} ${
          itemTwo.marks.includes('backgroundHighlightPeach') ? 'backgroundHighlightPeach' : ''
        } ${itemTwo.marks.includes('quoteCite') ? 'quoteTextRender' : ''} ${
          itemTwo.marks.includes('largeBodyText') ? 'largeBodyText' : ''
        } ${itemTwo.text === '' ? 'display-none' : ''}`}
        id={item.style?.includes('h2') ? itemTwo.text.replace('\n', '').trim() : ''}>
        {item.markDefs?.map((element: any) => (
          <>
            {itemTwo.marks.includes(element._key) && (
              <Link
                key={_key}
                href={element.href ? element.href : element.urlInternalLink}
                underline="hover"
                className={
                  buttonArray.includes(itemTwo.text.toUpperCase())
                    ? 'class-button-link'
                    : 'class-blog-link'
                }>
                {itemTwo.text}
              </Link>
            )}
          </>
        ))}
        <span>{itemTwo.text}</span>
      </span>
    )
  }

  const updateNumberCounter = (item: any) => {
    if (item.listItem === 'number') {
      counterNumber = counterNumber + 1
      return true
    } else {
      counterNumber = 0
      return false
    }
  }

  return (
    <div className="class-BlogBody">
      {dataBlog &&
        dataBlog?.map((item: any, _key: any) => {
          return (
            <>
              {item._type === 'image' ? (
                ImageAssetRender(item.asset, '', 'imageBlogPost')
              ) : (
                <div
                  className={`${item.style && item.style.split('')[0] === 'h' ? 'titleH' : ''}${
                    updateNumberCounter(item) ? 'containerNumber' : ''
                  }`}>
                  {counterNumber > 0 && <div className="numberListItem">{`${counterNumber}.`}</div>}
                  <span
                    className={`${item.style === 'normal' ? 'normal' : ''} ${
                      item.style === 'h2' ? ' h2' : ''
                    } ${item.style === 'h3' ? 'h3' : ''} ${item.style === 'h4' ? 'h4' : ''} ${
                      item.style === 'h5' ? 'h5' : ''
                    } ${item.style === 'h6' ? 'h6' : ''} ${
                      item.listItem === 'bullet' ? 'listItem-bullet' : ''
                    } ${item._type === 'block' ? 'display-block' : ''} ${
                      item.style === 'horizontalRule' ? 'horizontalRule' : ''
                    }`}>
                    {item.children?.map((itemTwo: any) => {
                      return (
                        itemTwo.text.length > 0 && (
                          <>
                            {itemTwo.marks.includes('quoteCite') ? (
                              <div className="containerQuote">
                                <div
                                  className="lineQuote"
                                  style={{
                                    height: `${30 * Math.ceil(itemTwo.text.length / 50)}px`,
                                  }}></div>
                                {renderBodyBlog(itemTwo, _key, item)}
                              </div>
                            ) : (
                              renderBodyBlog(itemTwo, _key, item)
                            )}
                          </>
                        )
                      )
                    })}
                  </span>
                </div>
              )}
            </>
          )
        })}
    </div>
  )
}

export default BlogBody
