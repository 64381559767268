import React, { useState, useEffect } from 'react'
import { Link, Typography } from '@material-ui/core'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { jumpLinkItem } from '../types/commonTypes'

const AccordionBlogContent = (props: { blogDataBody?: [jumpLinkItem] }) => {
  const [accordionBoolean, setAccordionBoolean] = useState(true)
  const [accordionElementsBoolean, setAccordionElementsBoolean] = useState(true)

  const { blogDataBody } = props

  React.useEffect(() => {
    if (
      blogDataBody?.length &&
      blogDataBody?.filter((item) => item?.style?.includes('h2')).length !== undefined &&
      blogDataBody?.filter((item) => item?.style?.includes('h2')).length == 0
    ) {
      setAccordionElementsBoolean(false)
    }
  }, [blogDataBody])

  const renderJumpLinksBlog = () => {
    return blogDataBody
      ?.filter((item) => item?.style?.includes('h2'))
      .map((item, _key) =>
        item.children?.map((itemTwo: any, keyTwo: any) => (
          <span
            key={`${keyTwo}-${_key}`}
            className={itemTwo.text === '' ? 'class-display-none' : ''}>
            <Link key={keyTwo.toString()} href={'#' + itemTwo.text.trim()} underline="hover">
              {itemTwo.text}
            </Link>
          </span>
        )),
      )
  }

  return (
    <>
      <Accordion className={accordionElementsBoolean ? '' : 'display-none'} expanded={true}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography className="class-heading-dropdown">Summary Menu</Typography>
        </AccordionSummary>

        <AccordionDetails>{renderJumpLinksBlog()}</AccordionDetails>
      </Accordion>
      {accordionBoolean && (
        <div
          className="bg-display-none"
          onClick={() => {
            setAccordionBoolean(false)
          }}
        />
      )}
    </>
  )
}

export default AccordionBlogContent
