import React, { FunctionComponent, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import theme from '../../common/Theme'
import cmsStaticPagesClient, { SanityEvergreenPage, urlFor } from './cmsStaticPagesClient'
import { useHistory, useParams } from 'react-router-dom'
import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core'
import BlockContentLayoutContainer from '../BlockContentLayoutContainer'
import changesMetaDescription from '../../scripts/changesMetaDescription'
import TopButton from '../../component/TopButton'
import '../../scss/evergreen.scss'

export const useStyles = makeStyles((theme: Theme) => ({
  classTitleEvergreenPagesLug: {
    fontSize: '3.8333rem',
    fontStyle: 'normal',
    fontFamily: 'IBM Plex Sans,sans-serif',
    fontWeight: 600,
    lineHeight: '72px',
    marginBottom: '25px',
    letterSpacing: '-0.5px',
  },
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
    '@media (max-width: 400px)': {
      marginLeft: theme.spacing(1.5),
    },
  },
  heroImage: {
    backgroundRepeat: 'no-repeat',
    minHeight: '221px',
    zIndex: 1000,
    backgroundPosition: 'center',
  },
  heroImageContainer: {
    marginLeft: -1 * theme.spacing(4),
    width: `calc(100vw)`,
    '@media (max-width: 900px)': {
      marginLeft: -1 * theme.spacing(5.5),
      width: `calc(103vw)`,
    },
  },
  contentContainer: {
    marginLeft: -1 * theme.spacing(10),
    marginRight: theme.spacing(4),
  },
  preTitle: {
    color: theme.palette.accentText.blue.main,
  },
  preTitleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: '72px',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: theme.typography.h3,
  },
  rootEvergreenPage: {
    maxWidth: theme.breakpoints.values.lg,
    marginBottom: theme.spacing(8),
  },
  containerMailSection: {
    flexWrap: 'nowrap',
  },
}))

const EvergreenPage: FunctionComponent = () => {
  const classes = useStyles(theme)
  const history = useHistory()
  const [emailSubscription, setEmailSubscription] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const { slug }: { slug: string } = useParams()
  const [pageData, setPageData] = React.useState<SanityEvergreenPage>({})

  const getEvergreenPageData = async (): Promise<void> => {
    let sanityEvergreenPage = await cmsStaticPagesClient.fetchEvergreenPage(slug)
    if (!sanityEvergreenPage) {
      history.push(`/blog/${slug}`)
    }
    setPageData(sanityEvergreenPage)
  }

  React.useEffect(() => {
    getEvergreenPageData().then()
  }, [])

  React.useEffect(() => {
    let titleTab = '| Assembled Brands'
    if (pageData.seo?.seo_title || pageData.title) {
      if (pageData.seo?.seo_title) {
        titleTab = `${pageData.seo?.seo_title} ${titleTab}`
      } else {
        titleTab = `${pageData.title} ${titleTab}`
      }
    } else {
      titleTab = `EvergreenPage ${titleTab}`
    }
    document.title = titleTab
    changesMetaDescription('description', pageData.seo?.meta_description)
  }, [pageData])

  const checkEmail = () => {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    if (!filter.test(emailSubscription)) {
      setValidEmail(false)
    } else {
      setValidEmail(true)
    }
  }

  React.useEffect(() => {
    if (emailSubscription.length > 0) {
      checkEmail()
    } else {
      setValidEmail(false)
    }
  }, [emailSubscription])

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      className={`${classes.root}  ${
        slug == 'portfolio-careers' ? ' class-portfolio-careers ' : ' '
      }`}>
      {pageData.mainImage && (
        <Grid
          container
          item
          alignItems="center"
          justify="center"
          className={classes.heroImageContainer + ' visible fadeBottom '}>
          <Grid
            container
            item
            xs={12}
            style={{
              backgroundImage: `url(${urlFor(pageData.mainImage).url() ?? ''})`,
            }}
            className={classes.heroImage + ' class-hero-image-animated '}
          />
        </Grid>
      )}
      <Grid
        container
        direction="column"
        item
        xs={12}
        md={9}
        className={classes.contentContainer + classes.rootEvergreenPage + ' class-evergreenPage'}>
        {pageData.mainImage && slug !== 'portfolio' && (
          <Grid item className={classes.preTitleContainer}>
            <Typography
              variant="overline"
              className={classes.preTitle + ' visible fadeBottom class-subTitle-EP'}>
              {slug === 'portfolio-careers' ? 'WORK IN CONSUMER GOODS' : 'THE COMPLETE GUIDE'}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          className={`${
            slug == 'portfolio'
              ? ' class-portfolio-container '
              : slug == 'portfolio-careers'
              ? ' portfolio-career-title '
              : ''
          }`}>
          <Typography
            variant="h1"
            className={`
          ${
            pageData.mainImage
              ? classes.title + ' class-title-EP ' + classes.classTitleEvergreenPagesLug
              : classes.title + ' class-pt-84px ' + classes.classTitleEvergreenPagesLug
          }
          ${slug == 'portfolio' ? ' class-portfolio-title ' : ''}
        `}>
            {pageData.title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          className={`${slug === 'portfolio-careers' ? 'portfolio-career-container' : ''}`}>
          <BlockContentLayoutContainer
            content={pageData?.pageContent}
            filterBolean={slug == 'portfolio'}
          />
        </Grid>
        {slug == 'portfolio-careers' && (
          <Grid container item direction="column" className={classes.containerMailSection}>
            <form
              className="class-subscribe"
              action="https://assembledbrands.us15.list-manage.com/subscribe/post?u=900869c4844b134da77ddcb20&amp;id=461068a1c3"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate>
              <h1>Stay in the know. Join our mailing list</h1>
              <div>
                {emailSubscription.length > 0 && <div className="legendEmail">Email</div>}
                <input
                  type="email"
                  placeholder="Email"
                  value={emailSubscription}
                  onChange={(e) => setEmailSubscription(e.target.value)}
                  name="EMAIL"
                  className="required email"
                  id="Email"
                />
                {!validEmail && emailSubscription.length > 0 && (
                  <div className="validEmail">Enter a valid email address</div>
                )}
              </div>
              <div className="hiddenDataForm">
                <div hidden>
                  <input type="hidden" name="tags" value="409030" />
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}></div>
                </div>
                <div style={{ position: 'absolute', left: ' -5000px' }} aria-hidden="true">
                  <input type="text" name="b_900869c4844b134da77ddcb20_461068a1c3" value="" />
                </div>
              </div>
              <Button
                disabled={!validEmail}
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button">
                SUBSCRIBE
              </Button>
            </form>
          </Grid>
        )}
      </Grid>
      <TopButton scrollDownLimit={1000} />
    </Grid>
  )
}

export default EvergreenPage
