import ReactGA from 'react-ga4'
import * as BingTracking from 'react-tracking-bing'
import {useEffect} from "react";

type trackingIdStrings = { [index: string]: string }

const trackingIds: trackingIdStrings = {
  'localhost': 'G-78SMW30ZLD',
  'dev.assembledbrands.com': 'G-78SMW30ZLD',
  'staging.assembledbrands.com': 'G-56KGM3WGE5',
  'assembledbrands.com': 'G-XC67PZHHTK',
}

const trackingIdsMicrosoft: trackingIdStrings = {
  'localhost': '52015375',
  'dev.assembledbrands.com': '52015375',
  'staging.assembledbrands.com': '52015375',
  'assembledbrands.com': '52015375',
}

const initialize = (location: Location): void => {
  ReactGA.initialize(trackingIds[location.hostname])
  BingTracking.initialize(trackingIdsMicrosoft[location.hostname]);
}

const pageView = (pathname: string): void => {
  if (pathname && pathname.length > 0) {
    ReactGA.send({
      hitType: "pageview", page: pathname
    })
    BingTracking.trackPageview();
  }
}

const setAnalyticsUserId = (userId: string | null): void => {
  ReactGA.set({userId: userId});
}

const userEvent = (action: string): void => ReactGA.event({
  category: 'User',
  action: action,
})

const userEventMicrosoft = (action: string): void => BingTracking.trackEvent(action, 'User')

const userEventHubspot = (action: string) => {
  if ((window as any)._hsq) {
    (window as any)._hsq.push([
      "trackEvent",
      {
        id: "User",
        value: action,
      },
    ]);
  }
};

export default {initialize, userEvent, userEventMicrosoft, setAnalyticsUserId, pageView, userEventHubspot}
