import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from "@material-ui/core/styles"
import theme from '../../common/Theme'
import {Grid, Link} from '@material-ui/core'
import logoCube from '../../common/assets/logo-cube.svg'
import logo from '../../common/assets/ab-logo.png'
import {useHistory} from 'react-router-dom'

export const useStyles = makeStyles((theme: Theme) => ({
  logoSection: {
    cursor: 'pointer',
  },
  logoText: {
    height: '36px',
    marginLeft: theme.spacing(1.5),
  },
}))

export type LogoProps = {

}

const Logo: FunctionComponent<LogoProps> = (props) => {
  const classes = useStyles(theme)
  const history = useHistory()

  return (
      <Link className={classes.logoSection} onClick={()=>history.push('/')}>
        <img src={logoCube} className=' class-logo-h '/>
        <img src={logo} alt="Assembled Brands" className={classes.logoText + " class-logo-h "}/>
      </Link>
  )
}

export default Logo