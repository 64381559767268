import { Grid, Hidden } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import theme from '../../../common/Theme'
import BlogPostPreview from './BlogPostPreview'
import cmsClient, { SanityBlogPreview } from '../../cmsClient'
import BlogSearchBar from './BlogSearchBar'
import BlogPostResults from './BlogPostResults'
import BlogGroup from './BlogGroup'
import HorizontalDivider from '../../shared/HorizontalDivider'
import changesMetaDescription from '../../../scripts/changesMetaDescription'
import cmsStaticPagesClient from '../../static-pages/cmsStaticPagesClient'
import { SanityMenuGroup } from '../../cmsClient'

import useMediaQueryValidation from '../../../scripts/useMediaQueryValidation'
import animationJsScroll from '../../../scripts/animationJsScroll'
import animationResponsiveJsScroll from '../../../scripts/animationResponsiveJsScroll'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    maxWidth: theme.breakpoints.values.lg,
  },
}))

const MainBlogPage: FunctionComponent = () => {
  const classes = useStyles(theme)
  const [latestBlog, setLatestBlog] = React.useState<SanityBlogPreview>({})
  const [searchResultBlogs, setSearchResultBlogs] = React.useState<SanityBlogPreview[]>([])
  const [blogSeo, setBlogSeo] = React.useState<SanityMenuGroup>({})
  const validateResponsiveSize = useMediaQueryValidation('(max-width: 980px)')

  const sortArray = (arr: SanityBlogPreview[]) => {
    return arr.sort((a: SanityBlogPreview, b: SanityBlogPreview) => {
      const firstComp = a.publishedAt ? a.publishedAt : a._createdAt
      const secondComp = b.publishedAt ? b.publishedAt : b._createdAt
      if (firstComp! > secondComp!) {
        return -1
      }
      if (firstComp! < secondComp!) {
        return 1
      }
      return 0
    })
  }

  const getAllBlogs = async (): Promise<void> => {
    const data = await cmsClient.fetchAllBlogPostPreviews()
    const sortedArray = sortArray(data)
    setLatestBlog(sortedArray[0])
    setSearchResultBlogs(sortedArray.slice(1))
  }

  React.useEffect(() => {
    getAllBlogs().then()
    validateResponsiveSize ? animationResponsiveJsScroll() : animationJsScroll()
  }, [])

  const getBlogSeo = async (): Promise<void> => {
    const data = await cmsStaticPagesClient.fetchBlogSeo()
    setBlogSeo(data)
  }

  React.useEffect(() => {
    getBlogSeo().then()
  }, [])

  React.useEffect(() => {
    if (blogSeo.seo?.seo_title || blogSeo.title) {
      const titleTab = blogSeo.seo?.seo_title ? blogSeo.seo?.seo_title : blogSeo.title
      document.title = titleTab + ' | Assembled Brands'
    } else {
      document.title = 'Blog | Assembled Brands'
    }
    changesMetaDescription('description', blogSeo.seo?.meta_description)
  }, [blogSeo])

  return (
    <Grid container item direction="column" spacing={1} className={classes.root}>
      <Grid container item className=" class-growBottomAnimation ">
        <BlogPostPreview preview={latestBlog} />
      </Grid>
      <Grid container direction="column" item className=" class-growBottomAnimation ">
        <BlogSearchBar updateSearchResult={setSearchResultBlogs} />
        <Grid container item>
          <Grid container item xs={12} md={8} className=" class-growBottomAnimation ">
            {searchResultBlogs[0] && (
              <BlogPostPreview preview={searchResultBlogs[0]} autoHideSnippet />
            )}
            <Grid container item>
              <HorizontalDivider />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={4} className=" class-growBottomAnimation ">
              <BlogGroup title="Essentials" />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={8} className="scroll-content fadeBottom">
        <BlogPostResults previews={searchResultBlogs.slice(1)} />
      </Grid>
    </Grid>
  )
}

export default MainBlogPage
