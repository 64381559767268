import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    TextField,
    Hidden,
    Grid,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

const useStylesNewsletter = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    emailField: {
        marginBottom: theme.spacing(2),
    },
    newsLetterText: {
        marginBottom: '20px',
        textDecoration: 'none',
        color: '#FDF3EB',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    popover: {
        boxShadow: 'none',
        borderLeft: `4px solid ${theme.palette.background.default}`,
        borderRadius: 0,
    },
    button: {
        backgroundColor: '#4d4d4d',
        '&:hover': {
            backgroundColor: '#3d3d3d',
        },
    },

    fontSizeTitle: {
        fontSize: '16px',
        fontWeight: "bold",
    },

    fontSizeSubTitle: {
        fontSize: '14px',
    },

    font: {
        fontFamily: 'IBM Plex Sans,sans-serif',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: '0.1px',
    },

    fullHeight: {
        height: '100%',
    },

    whiteText: {
        color: '#FDF3EB',
    },
}));

const NewsletterSignupForm: React.FC = () => {
    const classes = useStylesNewsletter();

    return (
        <div className={classes.root}>
            <form
                action="https://assembledbrands.us15.list-manage.com/subscribe/post?u=900869c4844b134da77ddcb20&amp;id=461068a1c3&amp;f_id=008198e1f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className={classes.form}
                target="_blank"
                noValidate
            >
                <Typography variant="subtitle1" className={`${classes.newsLetterText} ${classes.font} ${classes.fontSizeTitle}`}>Subscribe to our Newsletter!</Typography>
                <Typography variant="subtitle1" className={`${classes.newsLetterText} ${classes.font} ${classes.fontSizeSubTitle}`} gutterBottom>
                    Get the latest financing news, guides and more directly to your inbox
                </Typography>

                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={8}>
                        <TextField
                            type="email"
                            label="Email"
                            name="EMAIL"
                            id="mce-EMAIL"
                            required
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                className: classes.whiteText,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton style={{ color: '#FDF3EB' }} disabled>
                                            <EmailIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" color="primary" fullWidth className={`${classes.button} ${classes.fullHeight} ${classes.font}`}>
                            Subscribe
                        </Button>
                    </Grid>
                </Grid>

                <Hidden>
                    <input type="hidden" name="tags" value="409061" />
                </Hidden>

                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                    <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>

                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                    <input type="text" name="b_900869c4844b134da77ddcb20_461068a1c3" tabIndex={-1} value="" />
                </div>
            </form>
        </div>
    );
};

export default NewsletterSignupForm;
