import {makeStyles, Theme} from '@material-ui/core/styles'
import React, {FunctionComponent} from 'react'
import theme from '../../../common/Theme'
import {Grid, Link, Typography} from '@material-ui/core'
import cmsClient, {SanityBlogGroup} from '../../cmsClient'
import HorizontalDivider from '../../shared/HorizontalDivider'

const useStyles = makeStyles((theme: Theme) => ({
  groupTitle: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
  },
  underlineHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  previewBlogLink: {
    color: theme.palette.secondary.main,
    textAlign: 'end',
  },
}))

export type BlogGroupProps = {
  title: string
}

const BlogGroup: FunctionComponent<BlogGroupProps> = ({title}) => {
  const classes = useStyles(theme)

  const [blogGroup, setBlogGroup] = React.useState<SanityBlogGroup>({})

  const getBlogGroup = async (): Promise<void> => {
    const data = await cmsClient.fetchBlogGroup(title)
    setBlogGroup(data)
  }
  React.useEffect(() => {
    getBlogGroup().then()
  }, [])

  return (
    <Grid container item spacing={1} direction="column" alignItems="flex-end">
      <Grid item>
        <Typography className={classes.groupTitle} variant="overline">{blogGroup.title}</Typography>
      </Grid>
      {blogGroup.posts?.map((preview, index) => {
        // there may be a better way to do this? need to conditionally render a horizontal divider
        if (index + 1 == blogGroup.posts?.length) {
          return <Grid item key={preview.slug?.current}>
            <Link classes={{underlineHover: classes.underlineHover}} href={'/blog/' + preview.slug?.current}>
              <Typography variant="subtitle2" className={classes.previewBlogLink}>
                {preview.title}
              </Typography>
            </Link>
          </Grid>
        } else {
          return ([<Grid item key={preview.slug?.current}>
            <Link classes={{underlineHover: classes.underlineHover}} href={'/blog/' + preview.slug?.current}>
              <Typography variant="subtitle2" className={classes.previewBlogLink}>
                {preview.title}
              </Typography>
            </Link>
          </Grid>,
            <Grid container item key={`divider-${index}`}>
              <HorizontalDivider/>
            </Grid>,
          ])
        }
      })}
    </Grid>
  )
}

export default BlogGroup