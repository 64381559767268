import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import theme from '../../../common/Theme'
import {ListItemType, SanitySolutions} from '../cmsStaticPagesClient'
import {Grid} from '@material-ui/core'
import ListItem from './ListItem'
import BlockContentContainer from '../../BlockContentContainer'
import ImageCarousel from './ImageCarousel'

export const useStyles = makeStyles((theme: Theme) => ({

}))

export type SolutionsSectionProps = {
  sectionData?: SanitySolutions
}

const SolutionsSection: FunctionComponent<SolutionsSectionProps> = (props) => {
  const classes = useStyles(theme)

  React.useEffect(() => {

  }, [])

  return (
    <Grid container item direction="column" spacing={4}>
      <Grid item xs={12} md={10}>
        {props.sectionData?.sectionHeader && <BlockContentContainer body={props.sectionData?.sectionHeader}/>}
      </Grid>
      <Grid container item spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            {props?.sectionData?.solutionList?.map((item: ListItemType, index: number) => {
              return <ListItem key={'solution-list-item-' + index} title={item.title} description={item.description}/>
            })
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCarousel slug="home-page-carousel"/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SolutionsSection