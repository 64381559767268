import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Button, CircularProgress, FormControl, Grid, TextField, Typography} from '@material-ui/core'
import theme from '../../../../common/Theme'
import emailValidator from '../../../../services/emailValidator'
import contactClient, {ContactType} from '../../contactClient'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import {Utm} from '../../../utm'
import BlockContentContainer from '../../../BlockContentContainer'

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: '40px',
  },
  disabledButton: {},
  formControl: {
    width: '100%',
  },
  plexSubtitle1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#3D3D3D',
  },
}))

export type ReferralLeadProps = {
  instructions: string,
  utm: Utm | undefined
}

const ReferralLeadForm: FunctionComponent<ReferralLeadProps> = (props) => {

  const classes = useStyles(theme)

  const [validEmail, setValidEmail] = React.useState(true)
  const [completed, setCompleted] = React.useState(false)
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [company, setCompany] = React.useState('')

  const validateEmail = (newEmail: string): void => {
    newEmail.length > 0 ? setValidEmail(emailValidator.isValidEmail(newEmail)) : setValidEmail(true)
  }

  const onEmailChange = (newEmail: string): void => {
    setEmail(newEmail)
    validateEmail(newEmail)
  }

  const onSubmit = (): Promise<void> => {
    setFormSubmitting(true)
    const defaultUtm: Utm = {utmSource: 'default', utmMedium: 'default', utmCampaign: 'default'}
    return contactClient.createContact(email, ContactType.REFERRER_FACTORING, company, props.utm || defaultUtm).then(() => {
      setFormSubmitting(false)
      setCompleted(true)
    })
  }

  return (
    <Grid container direction="column" justify="center">
      <Grid item>
        <Typography component="span" className={classes.plexSubtitle1}>
          <BlockContentContainer body={props.instructions}/>
        </Typography>
      </Grid>
      <Grid container direction="column" alignContent="center" item spacing={6}>
        <Grid container justify="center" item>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth={true}
              label="Your Company Name"
              id="companyName"
              name="company name"
              type="text"
              value={company}
              onChange={(e): void => setCompany(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid container justify="center" item>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth={true}
              error={!validEmail}
              helperText={validEmail ? '' : 'Invalid email, please provide a valid address.'}
              label="Your Email"
              id="email"
              name="email address"
              type="email"
              value={email}
              onChange={(e): void => onEmailChange(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid container item>
          <Button
            color="primary"
            variant="contained"
            disabled={!(emailValidator.isValidEmail(email) && email.length > 0) || formSubmitting || completed}
            aria-label="Submit"
            classes={{disabled: classes.disabledButton}}
            className={classes.button}
            fullWidth={true}
            onClick={onSubmit}
          >
            {!formSubmitting && !completed && <Typography variant="button" align="center">Submit</Typography>}
            {!formSubmitting && completed && <DoneAllIcon aria-label="Completed"/>}
            {formSubmitting && <CircularProgress color="inherit" size="22px"/>}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReferralLeadForm