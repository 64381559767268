import React, { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { SanityHeroImageWithText } from '../cmsStaticPagesClient'
import { Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import theme from '../../../../src/common/Theme'

export const useStyles = makeStyles<Theme, HeroImageWithTextProps>((theme: Theme) => ({
  heroImageContainer: (props) => ({
    backgroundSize: 'contain',
    backgroundImage: `url(${props.heroImage?.mainImage?.asset?.url}), linear-gradient(103deg, ${props.heroImage?.gradient?.color1?.value}, ${props.heroImage?.gradient?.color2?.value})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      minHeight: '604px',
    },
    minHeight: '300px',
    zIndex: 1000,
    // '@media (max-width: 900px)': {
    //   marginLeft: 0 * theme.spacing(4),
    // },
  }),
  root: {
    marginLeft: -1 * theme.spacing(4),
    marginRight: -1 * theme.spacing(4),
    width: `calc(100vw + ${theme.spacing(8)}px)`,
  },
  firstIndent: {
    marginRight: theme.spacing(1),
    opacity: 0.11,
  },
  secondIndent: {
    marginRight: theme.spacing(5),
    opacity: 0.11,
  },
  mainIndent: {
    marginRight: theme.spacing(9),
    marginBottom: -1 * theme.spacing(5.5),
  },
  lightText: {
    fontWeight: 300,
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
  },
  smallTitle: {
    textAlign: 'center',
    '@media (max-width: 980px)': {
      marginTop: theme.spacing(11),
    },
  },
  capitalTitle: { marginBottom: -1 * theme.spacing(5.5) },
}))

export type HeroImageWithTextProps = {
  heroImage?: SanityHeroImageWithText
}

const HomePageHeroImage: FunctionComponent<HeroImageWithTextProps> = (props) => {
  const classes = useStyles(props)
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container item alignItems="center" justify="center" className={classes.root}>
      <Grid
        container
        item
        xs={12}
        alignContent="center"
        className={classes.heroImageContainer}
        spacing={2}>
        <Hidden smDown>
          <Grid
            container
            item
            justify={smDown ? 'center' : 'flex-end'}
            alignItems="center"
            className={classes.capitalTitle}
            xs={smDown ? 12 : 6}>
            <Grid item>
              <Typography color="secondary" variant="h1" className={classes.lightText}>
                Capital to
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems={smDown ? 'center' : 'flex-end'}
            item
            xs={smDown ? 12 : 1}>
            <Hidden smDown>
              <Grid item className={classes.firstIndent}>
                <Typography color="primary" variant="h1" className={classes.lightText}>
                  hire
                </Typography>
              </Grid>
              <Grid item className={classes.secondIndent}>
                <Typography color="primary" variant="h1" className={classes.lightText}>
                  order
                </Typography>
              </Grid>
            </Hidden>
            <Grid item className={classes.mainIndent}>
              <Typography color="secondary" variant="h1">
                <b>
                  g<span className={classes.underline}>row</span>.
                </b>
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.secondIndent}>
                <Typography color="primary" variant="h1" className={classes.lightText}>
                  sell
                </Typography>
              </Grid>
              <Grid item className={classes.firstIndent}>
                <Typography color="primary" variant="h1" className={classes.lightText}>
                  ship
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container justify="center">
            <Grid item className={classes.smallTitle}>
              <Typography
                component="span"
                color="secondary"
                variant="h2"
                className={classes.lightText}>
                Capital to{' '}
              </Typography>
              <Typography component="span" color="secondary" variant="h2">
                <b>
                  g<span className={classes.underline}>row</span>.
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default HomePageHeroImage
