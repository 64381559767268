export default {
  LEAD_STEP_ONE_SUCCESS: 'cold lead step 1 success',
  LEAD_STEP_ONE_STARTED: 'cold lead step 1 started',
  LEAD_STEP_ONE_FAIL: 'cold lead step 1 failure',

  LEAD_STEP_TWO_SUCCESS: 'cold lead step 2 success',
  LEAD_STEP_TWO_STARTED: 'cold lead step 2 started',
  LEAD_STEP_TWO_FAIL: 'cold lead step 2 failure',

  LEAD_STEP_THREE_SUCCESS: 'cold lead step 3 success',
  LEAD_STEP_THREE_SKIPPED: 'cold lead step 3 skipped',
  LEAD_STEP_THREE_STARTED: 'cold lead step 3 started',
  LEAD_STEP_THREE_FAIL: 'cold lead step 3 failure',

  LEAD_NEXT_STEPS_STARTED: 'cold lead next steps started'
}