import React, { FunctionComponent, useEffect, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { Grid } from '@material-ui/core'
import sanityClient from '../sanityClient'
import { blockSerializers } from '../common/sanityIo/BlockContentRenderer'
import { useCommonStyles } from '../common/sanityIo/CommonStyles'
import theme from '../common/Theme'
import '../scss/BlockContentLayoutContainer.scss'
import PortfolioCard from '../component/portfolioCard'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import EvergreenBody from '../component/EvergreenBody'
import { jumpLinkItem } from '../types/commonTypes'

export type BlockContentLayoutContainerProps = {
  content?: any
  classColumn4BlockContent?: string
  filterBolean?: boolean
}

const BlockContentLayoutContainer: FunctionComponent<BlockContentLayoutContainerProps> = (
  props,
) => {
  const classes = useCommonStyles(theme)
  const [industry, setIndustry] = useState('')
  const [contentPageArray, setContentPageArray] = useState<[jumpLinkItem] | undefined>()

  const { classColumn4BlockContent, filterBolean, content } = props

  const handleChange = (event: any) => {
    setIndustry(event)
  }

  function functionBrandNameOrderAlf(a: any, b: any) {
    if (a.brandName < b.brandName) return -1
    if (a.brandName > b.brandName) return 1
    return 0
  }

  function functionLoanSizeOrderAlf(a: any, b: any) {
    if (a.loanSize > b.loanSize) return -1
    if (a.loanSize < b.loanSize) return 1
    return 0
  }

  function functionIndustryOrderAlf(a: any, b: any) {
    if (a.industryCategory?.title < b.industryCategory?.title) return -1
    if (a.industryCategory?.title > b.industryCategory?.title) return 1
    return 0
  }

  const flatContentPage = () => {
    let arrayJumpLinks: any = []
    if (content?.content) {
      arrayJumpLinks = content?.content
        .filter((itemPage: any) => itemPage.content)
        .map((contentItem: any) => contentItem.content)
        .flat()
    }
    return arrayJumpLinks
  }

  useEffect(() => {
    const contentPageFlatten = flatContentPage()
    if (contentPageFlatten.length > 0) {
      setContentPageArray(contentPageFlatten)
    }
  }, [content])

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={filterBolean ? 'class-grid-portfolio ' : ''}>
      {filterBolean && (
        <div className="class-portfolioSelect">
          <label>Sort by</label>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">Choose one</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={industry}
              label="Industry"
              onChange={(e): void => handleChange(e.target.value as string)}>
              <MenuItem value="brandName">Name (A-Z)</MenuItem>
              <MenuItem value="industry">Industry (A-Z)</MenuItem>
              <MenuItem value="loanSize">Loan Size (Descending)</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {content?.content
        ?.sort((a: any, b: any) =>
          industry == 'brandName'
            ? functionBrandNameOrderAlf(a, b)
            : industry == 'loanSize'
            ? functionLoanSizeOrderAlf(a, b)
            : industry == 'industry'
            ? functionIndustryOrderAlf(a, b)
            : function () {
                return 0
              },
        )
        .map((columnLayoutContainer: any, index: number) => {
          switch (columnLayoutContainer._type) {
            case 'column1BlockContent':
              return (
                <Grid key={index} container item xs={12} className={classes.layoutContainer}>
                  <Grid item xs={12} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                </Grid>
              )
            case 'column2BlockContent':
              return (
                <Grid
                  key={index}
                  container
                  item
                  className={classes.layoutContainer + ' class-column2BlockContent'}
                  spacing={2}>
                  <Grid item xs={12} md={6} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column1.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column1.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column2.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                </Grid>
              )
            case 'column3BlockContent':
              return (
                <Grid
                  key={index}
                  container
                  item
                  className={classes.layoutContainer + ' class-column3BlockContent'}
                  spacing={3}>
                  <Grid item xs={10} md={4} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column1.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column1.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} md={4} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column2.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} md={4} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column3.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                </Grid>
              )
            case 'column4BlockContent':
              return (
                <Grid
                  key={index}
                  container
                  item
                  className={
                    classes.layoutContainer +
                    ' class-column4BlockContent ' +
                    classColumn4BlockContent
                  }
                  spacing={4}>
                  <Grid item xs={12} sm={3} md={6} lg={3} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column1.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column1.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={3} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column2.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={3} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column2.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={3} className=" scroll-content fadeBottom ">
                    {columnLayoutContainer.column2.content && (
                      <EvergreenBody
                        BodyData={columnLayoutContainer.column2.content}
                        JumpLinksArray={contentPageArray}
                      />
                    )}
                  </Grid>
                </Grid>
              )
            case 'portfolioBlockContent':
              return (
                <Grid
                  item
                  xs={12}
                  className={`scroll-content fadeBottom class-column4PortfolioContent ${
                    !columnLayoutContainer.mainImage ? 'withoutImg' : ''
                  }`}>
                  <PortfolioCard
                    portfolioBlockContent={columnLayoutContainer}
                    blockSerializers={blockSerializers}
                    projectId={sanityClient.config().projectId}
                    dataset={sanityClient.config().dataset}
                  />
                </Grid>
              )
          }
        })}
    </Grid>
  )
}

export default BlockContentLayoutContainer
