import {makeStyles, Theme} from '@material-ui/core/styles'
import React, {FunctionComponent} from 'react'
import theme from '../../common/Theme'
import {Grid, Typography} from '@material-ui/core'
import {SanityBlogCategory} from '../cmsClient'
import BlogCategory from './MainBlogPage/BlogCategory'
import dateUtils from '../../services/dateUtils'

const useStyles = makeStyles((theme: Theme) => ({
  noWrap: {
    flexWrap: 'nowrap',
    fontWeight: 500,
    color: 'rgba(0,0,0,.38)',
  },
}))

export type BlogMetadataProps = { publishedAt?: string, category?: SanityBlogCategory }

const BlogMetadata: FunctionComponent<BlogMetadataProps> = ({publishedAt, category}) => {
  const classes = useStyles(theme)

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <Typography variant="overline">
          <span className={classes.noWrap}>
            {publishedAt && dateUtils.getPublishedDate(publishedAt)}
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="overline">|</Typography>
      </Grid>
      <Grid item>
        {category && <BlogCategory category={category}/>}
      </Grid>
    </Grid>
  )
}

export default BlogMetadata