import http from '../../services/http'
import EnvConfig from '../../config'
import {Utm} from '../utm'

export enum ContactType {
  UNKNOWN = "UNKNOWN",
  REFERRER_FACTORING = "REFERRER_FACTORING"
}

const createContact = (email: string, contactType: ContactType, company: string, utm: Utm): Promise<void> => {
  return http.post(`${EnvConfig.apiUrl}/contact`,
    {
      email: email,
      contactType: contactType,
      company: company,
      utmSource: utm.utmSource,
      utmMedium: utm.utmMedium,
      utmCampaign: utm.utmCampaign
    }).then()
}

export default {createContact}