import React, {FunctionComponent} from 'react'
import BlockContent from '@sanity/block-content-to-react'
import sanityClient from '../sanityClient'
import {blockSerializers} from '../common/sanityIo/BlockContentRenderer'

export type BlockContentContainerProps = { body?: any | undefined }

const BlockContentContainer: FunctionComponent<BlockContentContainerProps> = ({body}) => {
  return <BlockContent
    blocks={body}
    serializers={blockSerializers}
    projectId={sanityClient.config().projectId}
    dataset={sanityClient.config().dataset}
    className="scroll-content fadeBottom"
  />
}

export default BlockContentContainer
