import {Grid, Typography} from '@material-ui/core'
import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useParams} from 'react-router-dom'
import theme from '../../common/Theme'
import ReferralLeadForm from './forms/referral-lead/ReferralLeadForm'
import cmsClient, {SanityLandingPage} from '../cmsClient'
import {Utm} from '../utm'
import HorizontalDivider from '../shared/HorizontalDivider'

export const useStyles = makeStyles((theme: Theme) => ({
  landingPageContent: {
    paddingBottom: theme.spacing(4),
  },
  heroImageContainer: {
    zIndex: 1000,
  },
  heroImage: {
    width: 'calc(100vw)',
  },
  welcomeMessageContainer: {
    alignSelf: 'flex-start',
  },
  welcomeMessage: {
    color: '#565190',
    fontWeight: 500,
  },
  plexH2Container: {
    alignSelf: 'flex-start',
  },
  plexH2: {
    fontSize: '60px',
    fontWeight: 600,
    lineHeight: '72px',
    letterSpacing: '-0.5px',
    color: '#3D3D3D',
  },
  bodyText: {
    fontFamily: 'DTL Documenta',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#3D3D3D',
    marginTop: theme.spacing(3),
  },
  horizontalDividerContainer: {
    width: '100%',
  },
}))

export type ColdLead = {
  email: string
  brandName: string
  website: string
  desiredLoanAmount: string
  revenue: string
  accountsReceivable: string
  inventory: string
}

export type StepProps = {
  lead: ColdLead,
  setLead: any
}

const LandingPages: FunctionComponent = () => {
  const classes = useStyles(theme)

  const {slug}: { slug: string } = useParams()
  const [landingPageData, setLandingPageData] = React.useState<SanityLandingPage>({})

  const getLandingPageData = async (): Promise<void> => {
    const data = await cmsClient.fetchLandingPage(slug)
    setLandingPageData(data)
  }

  React.useEffect(() => {
    getLandingPageData().then()
  }, [slug])

  const getUtm = (): Utm | undefined => {
    if (landingPageData) {
      return {
        utmSource: landingPageData.utmSource || '',
        utmMedium: landingPageData.utmMedium || '',
        utmCampaign: landingPageData.utmCampaign || '',
      }
    }
    return undefined
  }

  return (
    <>
      {landingPageData &&
      <Grid container item direction="column" alignItems="center">
        <Grid item xs={12} className={classes.heroImageContainer}>
          <img className={classes.heroImage} src={landingPageData?.mainImage?.asset.url} alt=""/>
        </Grid>
        <Grid container direction="column" alignItems="center" item className={classes.landingPageContent}
              xs={12} md={8}>
          <Grid item xs={12} className={classes.welcomeMessageContainer}>
            <Typography variant={'overline'}
                        className={classes.welcomeMessage}>{landingPageData.welcomeMessage}</Typography>
          </Grid>
          <Grid item className={classes.plexH2Container}>
            <Typography variant="h2" className={classes.plexH2}>{landingPageData.headerText}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.bodyText}>{landingPageData.body}</Typography>
          </Grid>
          <Grid container item xs={12} className={classes.horizontalDividerContainer}>
            <HorizontalDivider/>
          </Grid>
          <Grid container item direction="column" alignItems="center" xs={12} md={9}>
            {landingPageData.form?.abFormType.title === 'XLEAD' &&
            <ReferralLeadForm instructions={landingPageData.form.instructionBlock} utm={getUtm()}/>}
          </Grid>
        </Grid>
      </Grid>}

    </>
  )
}

export default LandingPages