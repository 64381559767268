import { Grid, Link, Typography } from '@material-ui/core'
import React, { FunctionComponent, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'
import theme from '../../../common/Theme'
import cmsClient, { SanityBlog } from '../../cmsClient'
import BlogMetadata from '../BlogMetadata'
import { ImageAssetRender } from '../../../common/sanityIo/BlockContentRenderer'
import BlogBody from '../MainBlogPage/BlogBody'
import changesMetaDescription from '../../../scripts/changesMetaDescription'
import TopButton from '../../../component/TopButton'
import AccordionBlogContent from '../../../component/AccordionBlogContent'
import '../../../scss/title.scss'

import BlockContentContainer from '../../BlockContentContainer'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
    marginBottom: theme.spacing(8),
  },
  mainImage: {
    width: '100%',
    height: '100%',
    maxWidth: '396px',
    minWidth: '296px',
  },
  sanityHighlight: {
    color: '#FB7C6A',
    display: 'inline-block',
  },
  responsiveTitle: {
    [theme.breakpoints.up('md')]: theme.typography.h4,
    letterSpacing: '0px',
  },
  imageBlogContainer: {
    '@media (max-width: 400px)': {
      marginLeft: -1 * theme.spacing(4.5),
    },
    '@media (max-width: 350px)': {
      marginLeft: -1 * theme.spacing(6.25),
    },
    '@media (max-width: 300px)': {
      marginLeft: -1 * theme.spacing(8),
    },
  },
}))

const SingleBlogPage: FunctionComponent = () => {
  const classes = useStyles(theme)
  const history = useHistory()

  const { slug }: { slug: string } = useParams()
  const [blogData, setBlogData] = React.useState<SanityBlog>({})
  const getBlogData = async (): Promise<void> => {
    const data = await cmsClient.fetchBlogPost(slug)
    setBlogData(data)
  }

  React.useEffect(() => {
    getBlogData().then()
  }, [slug])

  React.useEffect(() => {
    if (!blogData) {
      history.push('/blog')
    }
    if (blogData.seo?.seo_title || blogData.title) {
      const titleTab = blogData.seo?.seo_title ? blogData.seo?.seo_title : blogData.title
      document.title = titleTab + ' | Assembled Brands'
    } else {
      document.title = 'Page | Assembled Brands'
    }
    changesMetaDescription('description', blogData.seo?.meta_description)
  }, [blogData])

  return (
    <Grid
      container
      direction="column"
      item
      xs={12}
      md={10}
      className={classes.root + ' class-blogSlug'}>
      <Grid container direction="column" alignItems="center">
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6} className=" class-growUpAnimation ">
            <Grid container item xs={12} sm={12} md={12} direction="column" alignItems="center">
              <Grid item className={classes.imageBlogContainer}>
                {ImageAssetRender(
                  blogData?.mainImage,
                  blogData?.mainImageCaption,
                  classes.mainImage,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={12}
            md={6}
            className=" class-growUpAnimation ">
            <Grid item>
              <BlogMetadata
                category={blogData?.category}
                publishedAt={blogData?.publishedAt ? blogData?.publishedAt : blogData?._createdAt}
              />
            </Grid>
            <Grid item>
              <Typography
                color="textSecondary"
                variant="h1"
                className={classes.responsiveTitle + ' class-font-bold'}>
                {blogData?.title}
              </Typography>
              <Grid container item xs={12} className="class-container-link">
                <AccordionBlogContent blogDataBody={blogData?.body} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          {blogData?.body && <BlogBody BodyData={blogData?.body} />}

          {/* {blogData?.body && <BlockContentContainer body={blogData?.body} />} */}
        </Grid>
      </Grid>

      <TopButton scrollDownLimit={1000} />
    </Grid>
  )
}

export default SingleBlogPage
