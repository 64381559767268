import http from '../../services/http'
import EnvConfig from '../../config'
import {Utm} from '../utm'


const createLead = (email: string, utm: Utm): Promise<void> => {
  return http.post(`${EnvConfig.apiUrl}/lead/create-lead`,
    {email: email, utmSource: utm.utmSource, utmMedium: utm.utmMedium, utmCampaign: utm.utmCampaign}).then()
}

export type UpdateLeadRequest = {
  email: string,
  brandName?: string,
  website?: string,
  desiredLoanAmount?: string,
  revenue?: string,
  accountsReceivable?: string,
  inventory?: string,
  submit?: boolean,
  howDidYouHearAboutUs?: string,
  otherName?: string
}

const updateLead = (request: UpdateLeadRequest): Promise<void> => {
  return http.post(`${EnvConfig.apiUrl}/lead/update-lead`, request).then()
}

export default {createLead, updateLead}