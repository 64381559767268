import sanityClient from "@sanity/client"
import EnvConfig from './config'
// import EnvConfig from './config.ts'

export default sanityClient({
    projectId: "lpmw4qv1", // find this at manage.sanity.io or in your sanity.json
    dataset: EnvConfig.sanityDataset, //this is from those question during 'sanity init'
    apiVersion: '2021-03-25',
    useCdn: true,
});

