import React, { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Grid, useMediaQuery } from '@material-ui/core'
import HeroGradientWithText from '../shared/HeroGradientWithText'
import cmsStaticPagesClient, { SanityCommunityPage } from '../cmsStaticPagesClient'
import BlockContentContainer from '../../BlockContentContainer'
import theme from '../../../common/Theme'
import HowWeGrowBrands from '../shared/HowWeGrowBrands'
import HorizontalDivider from '../../shared/HorizontalDivider'
import PortfolioCompanies from '../shared/PortfolioCompanies'
import changesMetaDescription from '../../../scripts/changesMetaDescription'
import '../../../scss/title.scss'

import useMediaQueryValidation from '../../../scripts/useMediaQueryValidation'
import animationJsScroll from '../../../scripts/animationJsScroll'
import animationResponsiveJsScroll from '../../../scripts/animationResponsiveJsScroll'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    marginTop: '84px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  introductionContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  lastSection: {
    marginBottom: theme.spacing(16),
  },
}))

const Community: FunctionComponent = () => {
  const classes = useStyles(theme)
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [communityPage, setCommunityPage] = React.useState<SanityCommunityPage>({})
  const validateResponsiveSize = useMediaQueryValidation('(max-width: 980px)')

  const getCommunityPage = async (): Promise<void> => {
    setCommunityPage(await cmsStaticPagesClient.fetchCommunityPage())
  }

  React.useEffect(() => {
    if (communityPage.seo?.seo_title || communityPage.title) {
      const titleTab = communityPage.seo?.seo_title
        ? communityPage.seo?.seo_title
        : communityPage.title
      document.title = titleTab + ' | Assembled Brands'
    } else {
      document.title = 'CommunityPage | Assembled Brands'
    }
    document.title = communityPage.seo?.seo_title + ' | Assembled Brands'
    changesMetaDescription('description', communityPage.seo?.meta_description)
  }, [communityPage])

  React.useEffect(() => {
    getCommunityPage().then()
    validateResponsiveSize ? animationResponsiveJsScroll() : animationJsScroll()
  }, [])

  return communityPage ? (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={mdUp ? 10 : 6}
      className={classes.root}>
      <Grid item xs={12} container>
        <HeroGradientWithText
          text={communityPage.title}
          mainImage={communityPage.mainImage}
          gradient={communityPage.gradient}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        container
        justify="center"
        alignItems="center"
        className={classes.introductionContainer + ' scroll-content fadeBottom '}>
        {communityPage?.introduction && (
          <BlockContentContainer body={communityPage?.introduction} />
        )}
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HowWeGrowBrands
          title={communityPage.howWeGrowBrandsTitle}
          content={communityPage.howWeGrowBrandsContent}
        />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <PortfolioCompanies weWorkWithSection={communityPage?.weWorkWithSection} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        container
        justify="center"
        alignItems="center"
        className={classes.introductionContainer + ' scroll-content fadeBottom '}>
        {communityPage?.callToAction && (
          <BlockContentContainer body={communityPage?.callToAction} />
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default Community
