import React, { FunctionComponent } from 'react';
import theme from '../../../common/Theme';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SanityBlogPreview } from '../../cmsClient';
import BlogMetadata from '../BlogMetadata';

export const useStyles = makeStyles((theme: Theme) => ({
  previewBlogLink: {
    color: 'black',
  },
  underlineHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  previewBlogPost: {
    marginBottom: theme.spacing(4),
  },
  showMore: {
    fontFamily: 'IBM Plex Sans',
    color: 'black',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.4px',
    borderBottom: `4px solid ${theme.palette.background.default}`,
  },
  showMoreLink: {
    cursor: 'pointer',
  },
}));

type BlogPostResultsProps = {
  previews: SanityBlogPreview[];
};

const BlogPostResults: FunctionComponent<BlogPostResultsProps> = ({
  previews,
}) => {
  const classes = useStyles(theme);

  const batchCount = 10;
  const [visibleCount, setVisibleCount] = React.useState(batchCount);

  const onClickShowMore = () => {
    setVisibleCount((state) => state + batchCount);
  };
  return (
    <Grid container direction='column'>
      <Grid container item spacing={1}>
        {previews.slice(0, visibleCount).map((post) => {
          return (
            <Grid
              key={post.slug?.current}
              container
              item
              className={classes.previewBlogPost}
              xs={12}
              md={6}
            >
              <Link
                classes={{ underlineHover: classes.underlineHover }}
                href={'/blog/' + post.slug?.current}
                key={post.slug?.current}
              >
                <Grid item>
                  <BlogMetadata
                    publishedAt={
                      post.publishedAt ? post.publishedAt : post._createdAt
                    }
                    category={post.category}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant='subtitle1'
                    className={classes.previewBlogLink}
                  >
                    {post.title}
                  </Typography>
                </Grid>
              </Link>
            </Grid>
          );
        })}
      </Grid>
      {visibleCount <= previews.length && (
        <Grid container item>
          <Grid item>
            <Link
              classes={{ underlineHover: classes.underlineHover }}
              className={classes.showMoreLink}
              onClick={onClickShowMore}
            >
              <Typography variant='body1' className={classes.showMore}>
                Show More
              </Typography>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BlogPostResults;
