import sanityClient from '../../sanityClient'
import { SanityImage, SanityMenuGroup, SanitySlug, SanitySeo } from '../cmsClient'
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(sanityClient)

export type SanityHomePage = {
  slug?: SanitySlug
  seo?: SanitySeo
  heroImage?: SanityHeroImageWithText
  introduction?: string
  specializationsMenuGroup?: SanityMenuGroup
  weWorkWithSection?: SanityWeWorkWith
  ourServicesSection?: SanityOurServices
  solutions?: SanitySolutions
}

export type SanitySolutions = {
  sectionHeader?: string
  solutionList?: ListItemType[]
}

export type SanityWeWorkWith = {
  title?: string
  sectionHeader?: string
  description?: string
  companyPartnerLogos?: SanityImage[]
}

export type ListItemType = {
  title?: string
  description?: string
}

export type SanityOurServices = {
  title?: string
  sectionHeader?: string
  serviceList?: ListItemType[]
}

export type SanityGradient = {
  color1?: { title: string; value: string }
  color2?: { title: string; value: string }
}

export type SanityHeroImageWithText = {
  slug?: SanitySlug
  mainImage?: SanityImage
  gradient?: SanityGradient
}

export type SanitySimpleHeroImage = {
  slug?: SanitySlug
  text?: string
  mainImage?: SanityImage
  gradient?: SanityGradient
}

export type SanityBrandQuoteItem = {
  title?: string
  description?: string
  image?: SanityImage
}

export type SanityOurStoryPage = {
  slug?: SanitySlug
  titleText?: string
  seo?: SanitySeo
  mainImage?: SanityImage
  gradient?: SanityGradient
  introduction?: string
  storyStartTitle?: string
  storyStartLeft1?: string
  storyStartLeft2?: string
  storyStartLeft3?: string
  storyStartRightImage?: SanityImage
  brandQuotesTitle?: string
  brandQuotesList?: SanityBrandQuoteItem[]
  howWeGrowBrandsTitle?: string
  howWeGrowBrandsContent?: string
}

export type SanityCommunityPage = {
  slug?: SanitySlug
  seo?: SanitySeo
  title?: string
  mainImage?: SanityImage
  gradient?: SanityGradient
  introduction?: string
  howWeGrowBrandsTitle?: string
  howWeGrowBrandsContent?: string
  weWorkWithSection?: SanityWeWorkWith
  callToAction?: string
}

export type SanityImageAsset = {
  title?: string
  slug?: SanitySlug
  mainImage?: SanityImage
  caption?: string
}

export type SanityImageCarousel = {
  title?: string
  slug?: SanitySlug
  images?: SanityImageAsset[]
}

export type SanityStaticPage = {
  title?: string
  slug?: SanitySlug
  body?: string
}

export type SanityEvergreenPage = {
  mainImage?: SanityImage
  title?: string
  seo?: SanitySeo
  pageContent?: any
}

export type IndustryCategory = {
  title?: string
  _ref?: any
}

const fetchHomePage = (): Promise<SanityHomePage> => {
  return sanityClient
    .fetch(
      `*[_type=="homePage" && slug.current == "home"]{
          slug,
          seo,
          heroImage->{
            slug,
            mainImage{
              asset->{
                _id,
                url
              }
            },
            gradient
          },
          introduction,
          specializationsMenuGroup->{
            displayText,
            links[] -> {displayText, url}
          },
          weWorkWithSection {
            title,
            sectionHeader,
            description,
            companyPartnerLogos[]{
              asset->{
                _id,
                url
              }
            }
          },
          ourServicesSection {
            title,
            sectionHeader,
            serviceList[]{
              title,
              description
            }
          },
          solutions {
            sectionHeader,
            solutionList[] {
              title,
              description
            }
          }
       }[0]`,
    )
    .then((data: SanityHomePage) => {
      return data
    })
}

const fetchCommunityPage = (): Promise<SanityCommunityPage> => {
  return sanityClient
    .fetch(
      `*[_type=="communityPage" && slug.current == "community"]{
          slug,
          seo,
          title,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          gradient,
          introduction,
          howWeGrowBrandsTitle,
          howWeGrowBrandsContent{
            content
          },
          weWorkWithSection {
            title,
            sectionHeader,
            description,
            companyPartnerLogos[]{
              asset->{
                _id,
                url
              }
            }
          },
          callToAction
       }[0]`,
    )
    .then((data: SanityCommunityPage) => {
      return data
    })
}

const fetchOurStoryPage = (): Promise<SanityOurStoryPage> => {
  return sanityClient
    .fetch(
      `*[_type=="ourStoryPage" && slug.current == "our-story"]{
          slug,
          seo,
          titleText,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          gradient,
          introduction,
          storyStartTitle,
          storyStartLeft1,
          storyStartLeft2,
          storyStartLeft3,
          storyStartRightImage{
              asset->{
                _id,
                url
              }
            },
          brandQuotesTitle,
          brandQuotesList[]{
            title,
            description,
            image{
              asset->{
                _id,
                url
              }
            }
          },
          howWeGrowBrandsTitle,
          howWeGrowBrandsContent{
            content
          }
       }[0]`,
    )
    .then((data: SanityOurStoryPage) => {
      return data
    })
}

const fetchHomePageSpecializationsMenu = (): Promise<SanityMenuGroup> => {
  return sanityClient
    .fetch(
      `*[_type=="menuGroup" && slug.current == 'specializations']{
          slug,
          seo,
          displayText,
         "links": links[]->{displayText, url}
       }[0]`,
    )
    .then((data: SanityMenuGroup) => {
      return data
    })
}

const fetchApplyNow = (): Promise<SanityMenuGroup> => {
  return sanityClient
    .fetch(
      `*[_type=="menuGroup" && slug.current == 'apply-now']{
          slug,
          seo,
          displayText,
         "links": links[]->{displayText, url}
       }[0]`,
    )
    .then((data: SanityMenuGroup) => {
      return data
    })
}

const fetchBlogSeo = (): Promise<SanityMenuGroup> => {
  return sanityClient
    .fetch(
      `*[_type=="menuGroup" && slug.current == 'blog']{
          slug,
          seo,
          displayText,
         "links": links[]->{displayText, url}
       }[0]`,
    )
    .then((data: SanityMenuGroup) => {
      return data
    })
}

const fetchImageCarousel = (slug: string): Promise<SanityImageCarousel> => {
  return sanityClient
    .fetch(
      `*[_type=="imageCarousel" && slug.current == $slug]{
          title,
          slug,
          images[] -> { title, mainImage{
            asset->{
              _id,
              url
             }
           }}
       }[0]`,
      { slug },
    )
    .then((data: SanityImageCarousel) => {
      return data
    })
}

const fetchEvergreenPage = (slug: string): Promise<SanityEvergreenPage> => {
  return sanityClient
    .fetch(
      `*[_type=="abEvergreenPage" && slug.current == $slug]{
          title,
          seo,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          pageContent {
            'content': content[] {
              _type == 'column1BlockContent' => @,
              _type == 'column2BlockContent' => @,
              _type == 'column3BlockContent' => @,
              _type == 'column4BlockContent' => @,
              _type == 'jumplinkMenu' => @,
              _type == 'portfolioBlockContent' => {
                brandName,
                industryCategory->{title},
                loanSize,
                mainImage,
                sectionTitle,
                securedby,
                _key,
                _type
              },
            }
          }
       }[0]`,
      { slug },
    )
    .then((data: SanityEvergreenPage) => {
      return data
    })
}

const fetchIndustryCategory = (): Promise<IndustryCategory> => {
  return sanityClient
    .fetch(
      `*[_type=="industryCategory"]{
        title,
        _ref
     }[]`,
    )
    .then((data: IndustryCategory) => {
      return data
    })
}

export const urlFor = (source: any) => {
  return builder.image(source)
}

export default {
  fetchHomePage,
  fetchOurStoryPage,
  fetchCommunityPage,
  fetchHomePageSpecializationsMenu,
  fetchApplyNow,
  fetchBlogSeo,
  urlFor,
  fetchImageCarousel,
  fetchEvergreenPage,
  fetchIndustryCategory,
}
