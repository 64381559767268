import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import theme from '../../common/Theme'
import {Grid} from '@material-ui/core'
import HeaderMenuContainer from './HeaderMenuContainer'
import {useHistory} from 'react-router-dom'
import Logo from '../shared/Logo'
import ProcessSteps from '../static-pages/shared/processSteps/ProcessSteps'

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '84px',
  },
  logoSection: {
    cursor: 'pointer',
  },
  logoText: {
    height: '36px',
    marginLeft: theme.spacing(1.5),
  },
}))

export type FunctionComponentProps = {
  displayMenuProps: boolean
}

const Header: FunctionComponent<FunctionComponentProps> = (props) => {
  const classes = useHeaderStyles(theme)
  const history = useHistory()

  return (
    <Grid container item xs={12} justify={'space-between'} alignItems={'stretch'} className={classes.root}>
      <Grid container item xs={10} md={3} alignItems='center'>
        <Logo/>
      </Grid>
      <Grid container item xs={2} md={9} alignItems="center" justify="flex-end">
        <HeaderMenuContainer displayMenuProps={props.displayMenuProps}/>
      </Grid>
    </Grid>
  )
}

export default Header