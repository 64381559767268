const animationResponsiveJsScroll = () => {
    // window.addEventListener('load', function()  { 
      let elements = document.getElementsByClassName('scroll-content');
      let countTime = 1;
      // console.log("elements.length",elements.length)
      for(var i = 0; i < elements.length; i++) {
        let element = elements[i];
        setTimeout(() => element.classList.add('visible'),countTime);
        countTime++
      }
    // },false);
}

export default animationResponsiveJsScroll;