import React, {FormEvent, FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import cmsClient, {SanityBlogCategory, SanityBlogPreview} from '../../cmsClient'
import BlogCategory from './BlogCategory'
import theme from '../../../common/Theme'
import {Clear, Search} from '@material-ui/icons'
import HorizontalDivider from '../../shared/HorizontalDivider'

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  categoryTitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
  filterContainer: {
    marginTop: '18px',
  },
  largeFilterContainer: {
    height: '54px',
  },
  resultsForContainer: {
    marginTop: '2px',
    whiteSpace: 'nowrap',
  },
  pipe: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: 'rgba(0,0,0,.38)',
  },
  categorySelect: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
    '& .MuiSelect-icon': {
      color: '#000000',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  searchIcon: {
    color: '#DADADA',
  },
  clearButton: {
    padding: '2px',
  },
  resultsForTitle: {
    fontFamily: 'IBM Plex Sans',
    [theme.breakpoints.down('sm')]: {

      fontSize: '12px',
    },
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    color: 'rgba(0,0,0,.38)',
  },
  keywordContainer: {
    color: '#000000',
    fontWeight: 700,
    fontFamily: 'IBM Plex Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
  resultsTitleContainer: {
    marginRight: '30px',
    width: '100px !important',
  },
}))

export type BlogSearchBarProps = {
  updateSearchResult: (results: SanityBlogPreview[]) => void
}

const BlogSearchBar: FunctionComponent<BlogSearchBarProps> = ({updateSearchResult}) => {
  const classes = useStyles(theme)
  const isLargerScreenSize = useMediaQuery(theme.breakpoints.up('md'))
  const [allCategoriesData, setAllCategories] = React.useState<SanityBlogCategory[]>([])
  const [selectedCategory, setSelectedCategory] = React.useState<string>('All Articles')
  const [keyword, setKeyword] = React.useState<string>('')
  const [showingKeywordSearchResults, setShowingKeywordSearchResults] = React.useState<boolean>(false)
  const [searchFocused, setSearchFocused] = React.useState<boolean>(false)

  const getCategories = async (): Promise<void> => {
    let categories = await cmsClient.fetchBlogCategories()
    setAllCategories(categories)
  }

  React.useEffect(() => {
    getCategories().then()
  }, [])

  const searchByCategory = async (newCategory: string): Promise<void> => {
    if (newCategory !== selectedCategory) {
      if (newCategory === 'All Articles') {
        updateSearchResult((await cmsClient.fetchAllBlogPostPreviews()).slice(1))
      } else {
        updateSearchResult(await cmsClient.fetchBlogPostPreviewsByCategory(newCategory))
      }
      setSelectedCategory(newCategory)
    }
  }

  const searchByKeyword = async (e: FormEvent): Promise<void> => {

    e.preventDefault()
    if (keyword.length > 0) {
      updateSearchResult(await cmsClient.fetchBlogPostPreviewsByKeyword(keyword))
      setSearchFocused(false)
      setShowingKeywordSearchResults(true)
    }
  }

  const clearKeywordSearch = async (): Promise<void> => {
    updateSearchResult((await cmsClient.fetchAllBlogPostPreviews()).slice(1))
    setKeyword('')
    setShowingKeywordSearchResults(false)
  }

  const halfAndHalf = () => {
    return !isLargerScreenSize && !searchFocused && !showingKeywordSearchResults
  }

  const hideCategoryBar = () => {
    return !isLargerScreenSize && searchFocused
  }

  return (

    <Grid container item spacing={3} alignItems="center">

      {!hideCategoryBar() &&
      <Grid container item xs={halfAndHalf() ? 6 : 12} md={8}>
        <Grid container item alignItems="center" justify="flex-start" className={classes.filterContainer}>
          {!showingKeywordSearchResults &&
          <Hidden smDown>
            <Grid container item alignItems="center" justify="flex-start" spacing={3}
                  className={classes.largeFilterContainer}>
              <Grid item onClick={() => searchByCategory('All Articles')} className={classes.clickable}
                    aria-label="search-all-articles">
                <Typography color="textPrimary" className={classes.categoryTitle}>
                  {selectedCategory === 'All Articles' ? <b>All Articles</b> : <>All Articles</>}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.pipe}>|</Typography>
              </Grid>
              {allCategoriesData?.map(
                (category: SanityBlogCategory) =>
                  <Grid item
                        key={category.title}
                        onClick={() => searchByCategory(category.title)}
                        className={classes.clickable}
                        aria-label={`search-by-category-${category.title}`}>
                    <BlogCategory category={category}
                                  bold={category.title == selectedCategory}/>
                  </Grid>,
              )}
            </Grid>
          </Hidden>
          }

          {!showingKeywordSearchResults &&
          <Hidden mdUp>
            <Grid item>
              <FormControl>
                <Select
                  labelId="category-input-label"
                  id="category-select"
                  className={classes.categorySelect}
                  value={selectedCategory}
                  onChange={(e): Promise<void> => searchByCategory(e.target.value as string)}
                  disableUnderline={true}
                >
                  <MenuItem value="All Articles">All Articles</MenuItem>
                  {allCategoriesData?.map(
                    (category: SanityBlogCategory) =>
                      <MenuItem key={category.title} value={category.title}>{category.title}</MenuItem>,
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Hidden>
          }

          {showingKeywordSearchResults &&
          <Grid container item alignItems="center" justify="space-between" className={classes.resultsForContainer}>
            <Grid container item alignItems="stretch" justify="flex-start">
              <Grid container xs={12} md={1} item alignItems="center" className={classes.resultsTitleContainer}>
                <Typography className={classes.resultsForTitle}>Results for:</Typography>
              </Grid>
              <Grid container item xs={12} md={10} justify="space-between" alignItems="stretch">
                <Grid container xs={11} item alignItems="center">
                  <Typography className={classes.keywordContainer}>{keyword}</Typography>
                </Grid>
                <Grid container xs={1} item justify="flex-end">
                  <IconButton aria-label="clear search" className={classes.clearButton} onClick={clearKeywordSearch}>
                    <Clear/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          }
        </Grid>
        <Grid container item>
          <HorizontalDivider/>
        </Grid>
      </Grid>
      }

      {(isLargerScreenSize || !showingKeywordSearchResults) &&
      <Grid container direction="column" item xs={halfAndHalf() ? 6 : 12} md={4}>
        <Grid container item alignItems="center">
          <Grid item xs={12}>
            <form onSubmit={(e) => searchByKeyword(e)}>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search className={classes.searchIcon}/>
                    </InputAdornment>
                  ),
                }}
                id="input-with-icon-grid" label="Search"
                fullWidth
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                onChange={(e): void => setKeyword(e.target.value)}
                value={keyword}
              />
            </form>
          </Grid>
        </Grid>
        <Grid container item>
          <HorizontalDivider/>
        </Grid>
      </Grid>
      }
    </Grid>
  )
}

export default BlogSearchBar