import React, {FunctionComponent} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Typography, TypographyProps} from '@material-ui/core'
import {SanityBlogCategory} from '../../cmsClient'

export const useStyles = makeStyles<Theme, BlogCategoryProps>((theme: Theme) => ({
  categoryTitle: props => ({
    color: `${props.category?.color?.value}`,
    fontWeight: props.bold ? 700 : 400
  })
}))

export type BlogCategoryProps = {
  category: SanityBlogCategory
  bold?: boolean
}

const BlogCategory: FunctionComponent<BlogCategoryProps & TypographyProps> = (props) => {
  const classes = useStyles(props)
  return (<Typography variant="overline" className={classes.categoryTitle} noWrap>
      {props.category.title}
    </Typography>
  )
}

export default BlogCategory
