import React, {useEffect} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {CssBaseline, Grid, MuiThemeProvider} from '@material-ui/core'
import PreSignup from './application-layout/pre-signup/PreSignup'
import useGoogleAnalytics from './services/useAnalytics'
import MainLayout from './application-layout/MainLayout'
import {makeStyles, Theme} from '@material-ui/core/styles'
import theme from './common/Theme'
import "./scss/style.scss";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflowX: 'hidden'
    },
    content: {
        width: `calc(100vw - ${theme.spacing(1)}px)`,
        marginLeft: theme.spacing(1),
    },
}))

function Routes() {
    useGoogleAnalytics();
    //useHubSpotTracking();

    // Get current location pathname
    const location = useLocation();
    // Trigger HubSpot pageview on pathname change
    useEffect(() => {
        if ((window as any)._hsq) {
            // Update and track current page path
            (window as any)._hsq.push(['setPath', location.pathname + location.search]);
            (window as any)._hsq.push(['trackPageView']);
        }
    }, [location.pathname]);

    return (
        <Switch>
            <Route path="/apply" component={PreSignup}/>
            <Route path="/" component={MainLayout}/>
            <Redirect to="/"/>
        </Switch>
    )
}

type trackingIdStrings = { [index: string]: string }

const trackingIds: trackingIdStrings = {
    'localhost': '2473032',
    'dev.assembledbrands.com': '2473032',
    'staging.assembledbrands.com': '2473032',
    'assembledbrands.com': '2473032',
}

/*const useHubSpotTracking = () => {
    const location = useLocation()

    useEffect(() => {
        // Load HubSpot tracking code script
        const script = document.createElement('script')
        script.src = `https://js.hs-scripts.com/${trackingIds[window.location.hostname]}.js`
        script.async = true
        document.body.appendChild(script)

        // Remove script on unmount
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    useEffect(() => {
        // Track page view on location change
        if ((window as any)._hsq) {
            (window as any)._hsq.push(['setPath', location.pathname + location.search])
            (window as any)._hsq.push(['trackPageView'])
        }
    }, [location])
}*/

const useHubSpotTracking = ({hubId}: { hubId: string }) => {
    useEffect(() => {
        // Load HubSpot script
        const script = document.createElement('script')
        script.src = `https://js.hs-scripts.com/${hubId}.js`
        script.async = true
        document.body.appendChild(script)

        // Remove HubSpot script
        return () => {
            document.body.removeChild(script)
        }
    }, [hubId])
}

function App() {
    const classes = useStyles(theme)

    // Use custom hook here with your HubSpot ID
    useHubSpotTracking({
        hubId: trackingIds[window.location.hostname]
    });

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <Grid container className={classes.root}>
                <Grid item container className={classes.content}>
                    <Router>
                        <Routes/>
                    </Router>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    )
}

export default App