import React, { FunctionComponent } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import theme from '../../common/Theme'
import { Grid, useMediaQuery } from '@material-ui/core'
import PortfolioCompanies from './shared/PortfolioCompanies'
import ProcessSteps from './shared/processSteps/ProcessSteps'
import HeadlineWithList from './shared/HeadlineWithList'
import HorizontalDivider from '../shared/HorizontalDivider'
import HomePageHeroImage from './shared/HomePageHeroImage'
import cmsStaticPagesClient, { SanityHomePage } from './cmsStaticPagesClient'
import SpecializationsMenu from './shared/SpecializationsMenu'
import BlockContentContainer from '../BlockContentContainer'
import SolutionsSection from './shared/SolutionsSection'
import changesMetaDescription from '../../scripts/changesMetaDescription'
import '../../scss/homepage.scss'

import useMediaQueryValidation from '../../scripts/useMediaQueryValidation'
import animationJsScroll from '../../scripts/animationJsScroll'
import animationResponsiveJsScroll from '../../scripts/animationResponsiveJsScroll'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  introductionContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

export type HomePageProps = {}

const HomePage: FunctionComponent<HomePageProps> = (props) => {
  const classes = useStyles(theme)
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [homePage, setHomePage] = React.useState<SanityHomePage>({})
  const validateResponsiveSize = useMediaQueryValidation('(max-width: 980px)')

  document.title = homePage.seo?.seo_title + ' | Assembled Brands'
  changesMetaDescription('description', homePage.seo?.meta_description)

  const getHomePage = async (): Promise<void> => {
    setHomePage(await cmsStaticPagesClient.fetchHomePage())
  }

  React.useEffect(() => {
    getHomePage().then()
    validateResponsiveSize ? animationResponsiveJsScroll() : animationJsScroll()
  }, [])

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      spacing={mdUp ? 10 : 6}
      className={classes.root}>
      <Grid item xs={12} container>
        <HomePageHeroImage heroImage={homePage.heroImage} />
      </Grid>
      <Grid item xs={12} sm={10} className="scroll-content fadeBottom">
        <Grid container direction="column" spacing={4}>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            className={classes.introductionContainer}>
            {homePage?.introduction && <BlockContentContainer body={homePage?.introduction} />}
          </Grid>
          <Grid item xs={12} container className=" class-modify-h4 ">
            <SpecializationsMenu menuGroup={homePage?.specializationsMenuGroup} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} className="scroll-content fadeBottom">
        <PortfolioCompanies weWorkWithSection={homePage?.weWorkWithSection} />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} className="scroll-content fadeBottom">
        <ProcessSteps />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} className="scroll-content fadeBottom">
        <HeadlineWithList sectionData={homePage?.ourServicesSection} />
      </Grid>
      <Grid item xs={12} sm={10} container className="scroll-content fadeBottom">
        <HorizontalDivider />
      </Grid>
      <Grid item xs={12} sm={10} className="scroll-content fadeBottom">
        <SolutionsSection sectionData={homePage?.solutions} />
      </Grid>
    </Grid>
  )
}

export default HomePage
