import {Grid} from '@material-ui/core'
import React, { FunctionComponent , useState, useEffect } from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Redirect, Route, Switch} from 'react-router-dom'
import theme from '../common/Theme'
import Header from './header/Header'
import Footer from './footer/Footer'
import LandingPages from './landing-pages/LandingPages'
import SingleBlogPage from './blog/SingleBlogPage/SingleBlogPage'
import MainBlogPage from './blog/MainBlogPage/MainBlogPage'
import HomePage from './static-pages/HomePage'
import EvergreenPage from './static-pages/EvergreenPage'
import OurStoryPage from './static-pages/our-story/OurStoryPage'
import CommunityPage from './static-pages/community/CommunityPage'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'calc(100vw - 8px)'
  },
  sectionContainer: {
    marginTop: '84px',
    padding: theme.spacing(0,4,0,3)
  },
  headerSectionContainer: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    zIndex: 1001,
    left: 0,
    padding: theme.spacing(0,4,0,3)
  },
  contentContainer: {
    backgroundColor: theme.palette.background.paper
  },
  landingPageContainer: {
    overflow: 'hidden'
  },
  appBar: {
    backgroundColor: theme.palette.background.paper
  },
  abLogo: {
    height: '32px',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(4)
  }
}))


const MainLayout: FunctionComponent = () => {
  const classes = useStyles(theme)
  const [menuBoolean, setMenuBoolean] = useState(false);
  let compareOldScrollTop = document.documentElement.scrollTop;

  const scrollSeeMenuItem = () =>{
    switch(true){
      case document.documentElement.scrollTop < compareOldScrollTop || document.documentElement.scrollTop < 40:
        setMenuBoolean(false);
      break;
      case document.documentElement.scrollTop > compareOldScrollTop || document.documentElement.scrollTop > 40:
        setMenuBoolean(true);
      break;
    }
    compareOldScrollTop = document.documentElement.scrollTop;
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollSeeMenuItem);
  });

  return (
    <Grid item>
      <Grid container direction="column" className={classes.root}>
        <Grid container item direction="column" alignItems="center" className={classes.contentContainer}>
          <Grid container item className={menuBoolean ? classes.headerSectionContainer + " class-dropDownAnimation" : classes.headerSectionContainer + " class-dropUpAnimation"}>
            <Header displayMenuProps={menuBoolean}/>
          </Grid>
          <Grid container direction="column" item className={classes.sectionContainer} alignItems="center" justify="center">
            <Switch>
              <Route path="/hello/:slug" component={LandingPages}/>
              <Route path="/our-story" exact component={OurStoryPage}/>
              <Route path="/community" exact component={CommunityPage}/>
              <Route path="/blog" exact component={MainBlogPage}/>
              <Route path="/blog/:slug" component={SingleBlogPage}/>
              <Route path="/:slug" exact component={EvergreenPage}/>
              <Route path="/" exact component={HomePage}/>
              <Redirect to="/"/>
            </Switch>
          </Grid>
        </Grid>
        <Grid container item>
          <Footer/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MainLayout