import React, { useState } from 'react'
import { ImageAssetRender } from '../common/sanityIo/BlockContentRenderer'
import cmsStaticPagesClient, {
  IndustryCategory,
} from '../application-layout/static-pages/cmsStaticPagesClient'

const PortfolioCard = (props: {
  portfolioBlockContent?: any
  blockSerializers?: any
  projectId?: any
  dataset?: any
}) => {
  const [display, setDisplay] = useState(false)
  const [industryCategoryData, setIndustryCategoryData] = React.useState<IndustryCategory>({})

  const getIndustryCategory = async (): Promise<void> => {
    let sanityIndustryCategory = await cmsStaticPagesClient.fetchIndustryCategory()
    setIndustryCategoryData(sanityIndustryCategory)
  }

  const { brandName, loanSize, securedby, industry, mainImage, industryCategory } =
    props.portfolioBlockContent

  React.useEffect(() => {
    getIndustryCategory().then()
  }, [])

  return (
    <div className="container-portfolio-card">
      {(brandName || loanSize || securedby || industry) && (
        <button onMouseOver={() => setDisplay(true)} onMouseOut={() => setDisplay(false)} />
      )}
      {!display && mainImage && (
        <div className=" class-animation-img ">{ImageAssetRender(mainImage)}</div>
      )}
      {!display && !mainImage && <h2 className=" class-animation ">{brandName}</h2>}
      {display && (
        <div className=" class-animation class-data">
          <p>
            <strong>Brand Name: </strong>
            {brandName ? brandName : 'N/A'}
          </p>
          <p>
            <strong>Loan Size: </strong>
            {loanSize ? loanSize : 'N/A'}
          </p>
          <p>
            <strong>Secured By: </strong>
            {securedby ? securedby : 'N/A'}
          </p>
          <p>
            <strong>Industry: </strong>
            {industryCategory && industryCategory.title ? industryCategory.title : 'N/A'}
          </p>
        </div>
      )}
    </div>
  )
}

export default PortfolioCard
